<script lang="ts">
	import { onMount } from "svelte";
	import { fetchModels, textGeneration, checkLogin } from "./api";
	import { marked } from "marked";

	let models: Model[] = [];
	let error: string | null = null;
	let selectedModel: string | null = null;
	let userText = "";
	let generatedText = "";

	// svelte-ignore export_let_unused
	export let name: string;

	// Login-related variables
	let username = "";
	let password = "";
	let message = "";
	let loggedIn = false;

	$: if (loggedIn) {
		fetchModels()
			.then((fetchedModels) => {
				models = fetchedModels;
				// groupModelsByType(models);
			})
			.catch((err) => {
				error = err.message;
			});
	}

	onMount(async () => {});

	// function groupModelsByType(models: Model[]) {
	// 	groupedModels = models.reduce((acc, model) => {
	// 		if (!acc.has(model.type)) {
	// 			acc.set(model.type, []);
	// 		}
	// 		acc.get(model.type).push(model);
	// 		return acc;
	// 	}, new Map());
	// }

	async function generateText() {
		const model = models.find((model) => model.name === selectedModel);
		if (!model) {
			return;
		}

		const result = await textGeneration(model.name, userText);
		generatedText = result.results[0].generated_text;
	}

	async function handleLogin(event: Event) {
		event.preventDefault();
		const response = await checkLogin(username, password);
		if (response.ok) {
			loggedIn = true;
		} else {
			message = "Login failed";
		}
	}
</script>

{#if !loggedIn}
	<form on:submit|preventDefault={handleLogin}>
		<label>
			Username:
			<input type="text" bind:value={username} />
		</label>
		<label>
			Password:
			<input type="password" bind:value={password} />
		</label>
		<button type="submit">Login</button>
	</form>
	<p>{message}</p>
{:else if error}
	<p>{error}</p>
{:else}
	<select bind:value={selectedModel}>
		{#each models as model}
			<option value={model.name}>{model.name}</option>
		{/each}
	</select>

	<textarea bind:value={userText} placeholder="Enter your text here"></textarea>
	<button on:click={generateText}>Generate</button>

	{@html marked(generatedText)}
{/if}
