let API_URL = "http://127.0.0.1:8080";
if (window.location.hostname !== "localhost") {
    API_URL = "https://api.kaoru.cat";
}
export async function checkLogin(username, password) {
    const response = await fetch(`${API_URL}/check-login`, {
        method: "POST",
        headers: {
            Authorization: "Basic " + btoa(`${username}:${password}`),
        },
    });
    return response;
}
export async function fetchModels() {
    const response = await fetch(`${API_URL}/models`, {
        method: "GET",
        headers: {
            Authorization: "Basic dGVzdDp0ZXN0",
        },
    });
    if (!response.ok) {
        throw new Error("Failed to fetch models");
    }
    let models = await response.json().then((data) => {
        return data.filter((model) => model.type === "text-generation");
    });
    return models;
}
export async function textGeneration(modelId, text) {
    const response = await fetch(`${API_URL}/ai/text-generation`, {
        method: "POST",
        headers: {
            Authorization: "Basic dGVzdDp0ZXN0",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            model: modelId,
            system: "Listen to the user and answer any questions.",
            user: text,
        }),
    });
    if (!response.ok) {
        throw new Error("Failed to generate text");
    }
    return await response.json();
}
